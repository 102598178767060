body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {

  background: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 22, 22, 0.1)), to(rgba(22, 22, 22, 0.5))), url(/static/media/unsplash1.a1f31fae.jpg);
  background: -webkit-linear-gradient(top, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash1.a1f31fae.jpg);
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash1.a1f31fae.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(40px + 2vmin);
}

.App-link {
  color: #61dafb;
}
.App-skill{
  /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(92, 77, 66, 0.8)), to(rgba(92, 77, 66, 0.8))), url("/img/Louvre.jpg"); */
  /* background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url("img/unsplash3.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  color: white !important;
  min-height: 100vh;
}

.App-portfolio{
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 22, 22, 0.1)), to(rgba(22, 22, 22, 0.5))), url(/static/media/unsplash2.8ee6597b.jpg);
  background: -webkit-linear-gradient(top, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash2.8ee6597b.jpg);
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash2.8ee6597b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  min-height: 100vh;
  padding-top: 2rem;
}

.App-contact{
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(92, 77, 66, 0.8)), to(rgba(92, 77, 66, 0.8))), url("/img/Louvre.jpg");
  background: -webkit-linear-gradient(top, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash5.676eaf6a.jpg);
  background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url(/static/media/unsplash5.676eaf6a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
}

.App-nav
{
  color: white !important;
}
.hide{
  display: none;
}
.show{
  display: inline;
}
.progress{
  width: 30rem;
  height: 1.4rem;
}
@media screen and (max-width: 640px) {
  .progress{
  width: 15rem !important;
  }
  #email{
  font-size: 1rem;
  }
  #developper{
  font-size: 1rem;
  }
}
#about{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.cardpadding{
  padding-bottom: 2rem !important;
}
